<template>
  <div class="article-item" @click.stop="jump">
    <div>
      <img
        class="cover"
        :src="item.iconPath || require('../assets/img/default_img.jpg')"
      />
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;
      "
    >
      <div style="display: flex">
        <div class="title">{{ item.title }}</div>
        <div class="more">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="del">
                <i style="color: red" class="el-icon-delete"></i
                ><span style="color: #000">删除</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="view-count">
        <img src="../assets/img/home/uv.png" />
        <div style="margin-left: 5px">{{ item.viewCount }}</div>
        <div style="margin-left: 5px">学过</div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleItem",
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    //下拉框点击事件
    handleCommand(command) {
      if (command === "del") {
        this.$emit("onDel", this.item.id);
      }
    },
    //跳转
    jump() {
      this.$router.push({
        path: `/articleDetail/${ this.item.id}`
      });
    },
  },
};
</script>

<style lang="less" scoped>
.article-item {
  display: flex;
  cursor: pointer;
  &:hover {
    .title {
      color: #3b89ff;
    }
  }

  .cover {
    width: 84px;
    height: 84px;
    border-radius: 4px;
    background-color: #f3f4f6;
    vertical-align: middle;
  }
  .title {
    font-size: 15px;
    height: 64px;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .more {
    flex-shrink: 0;
  }
  .view-count {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-size: 13px;
    color: rgba(60, 60, 67, 0.6);
    img {
      width: 12px;
      height: 12px;
    }
  }
  .line {
    height: 1px;
    background-color: #e8e8e8;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>
