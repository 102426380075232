<template>
  <div class="userInfo">
    <Header />
    <div class="banner">
      <div class="nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>基本信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="left">
          <div class="user">
            <div class="imgs">
              <img v-if="user.headImg" :src="user.headImg" alt="" />
              <img v-else src="../assets/img/user.png" alt="" />
            </div>
            <h6>{{ user.name }}</h6>
            <span class="num">工号 {{ user.username }}</span>
            <el-button type="primary" @click="handelto('chapter')"
              >去练习</el-button
            >
            <el-button plain type="default" @click="logout">切换账号</el-button>
          </div>
          <div class="list">
            <el-row class="tac">
              <el-col :span="24">
                <el-menu
                  router
                  default-active="1"
                  class="el-menu-vertical-demo"
                  active-text-color="#007AFF"
                >
                  <el-menu-item
                    @click="changeTab(index)"
                    v-for="(item, index) in menus"
                    :key="index"
                  >
                    <span
                      :class="active === index ? 'active' : ''"
                      slot="title"
                      >{{ item }}</span
                    >
                    <img src="../assets/img/home/right.png" alt="" />
                  </el-menu-item>
                </el-menu>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="right">
          <div class="main">
            <div class="head">
              <div class="title">
                {{ msg }}
              </div>

              <div class="xian"></div>
            </div>
            <!-- 基本信息 -->
            <div class="msgbox" v-if="active === 0">
              <div class="boxleft">
                <div class="img">
                  <img v-if="user.headImg" :src="user.headImg" alt="" />
                  <img v-else src="../assets/img/user.png" alt="" />
                </div>

                <div class="username">{{ user.name }}</div>
              </div>
              <div class="cut"></div>
              <div class="boxright">
                <table>
                  <tr>
                    <td class="label" align="right">工号：</td>
                    <td>{{ user.username }}</td>
                  </tr>
                  <tr>
                    <td class="label" align="right">性别：</td>
                    <td>{{ gender }}</td>
                  </tr>

                  <tr>
                    <td class="label" align="right">身份证号码：</td>
                    <td>{{ user.idNumber || "-" }}</td>
                  </tr>

                  <tr>
                    <td class="label" align="right">职务：</td>
                    <td>{{ user.postCode || "-" }}</td>
                  </tr>
                  <tr>
                    <td class="label" align="right">总学习时长：</td>
                    <td>{{ studytime || 0 }}小时</td>
                  </tr>
                </table>
              </div>
            </div>
            <!--我的课程-->
            <div style="margin-top: 26px" v-if="active === 1">
               <empty v-if="myCourseEmpty"></empty> 
              <my-course-item
                v-for="(item, index) in myCourseList"
                :key="index"
                :item="item"
                @onDel="handleDelMyCourse"
              ></my-course-item>

              <div class="page">
                            <el-pagination
                            :hide-on-single-page="true"
                            @current-change="myCoursePageChange"
                            background
                            :page-size="myCoursePageSize"
                            layout="total, prev, pager, next"
                            :total="myCourseTotal">
                            </el-pagination>
                </div>
            </div>
            <!--我收藏的文章-->
            <div style="margin-top: 20px;" v-if="active === 2">
                <empty v-if="myArticleCollectEmpty"></empty> 
                <div v-for="(item,index) in myCollectArticleList" :key="index">
               <my-article-item  :item="item" @onDel="delMyArticle"></my-article-item>
              </div>
               <div class="page">
                            <el-pagination
                            :hide-on-single-page="true"
                            @current-change="myCollectArticlePageChange"
                            background
                            :page-size="myArticleCollectPageSize"
                            layout="total, prev, pager, next"
                            :total="myArticleCollectTotal">
                            </el-pagination>
                </div>
            </div>
            <!-- 修改密码 -->
            <div class="msgbox" v-if="active === 3">
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="原密码" prop="oldPassword">
                  <el-input
                    type="password"
                    placeholder="原密码"
                    v-model="ruleForm.oldPassword"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="confirmPassword">
                  <el-input
                    type="password"
                    placeholder="新密码"
                    v-model="ruleForm.confirmPassword"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="password">
                  <el-input
                    type="password"
                    placeholder="确认密码"
                    v-model="ruleForm.password"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm(ruleForm)"
                    >保 存</el-button
                  >
                  <el-button @click="resetForm('ruleForm')">重 置</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import MyCourseItem from "../components/MyCourseItem.vue";
import MyArticleItem from '@/components/MyArticleItem.vue'
import Empty from '@/components/Empty'
import {
  GetRecordList,
  Getstatics,
  GetDict,
  JoinOrDelCourse,
  AericleCollect
} from "../api/list";
import { PostUserPass, GetMyCourseList,GetMyCollectArticleList } from "../api/user";
 
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("原密码不能为空"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.confirmPassword !== "") {
          this.$refs.ruleForm.validateField("confirmPassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      i: 0, //答题记录页数
      page: 4,
      gender: "",
      num: "", //路由标记
      msg: "", //标签选择标记
      active: 0,
      record: false, //答题记录显示
      studytime: 0, //学习时间
      name: "", //职务名称
      user: "", //用户信息
      total: 0,
      list: [],
      menus: ["基本信息", "我的课程", "收藏的文章", "修改密码"],
      myCourseList: [],
      myCourseTotal:1,
      myCoursePage: 1,
      myCoursePageSize: 4,
      myCourseEmpty:false,
      myCollectArticleList:[],
      myArticleCollectTotal:1,
      myArticleCollectPage:1,
      myArticleCollectPageSize:6,
      myArticleCollectEmpty:false,

      ruleForm: {
        confirmPassword: "",
        oldPassword: "",
        password: "",
      },
      rules: {
        confirmPassword: [{ validator: validatePass, trigger: "blur" }],
        password: [{ validator: validatePass2, trigger: "blur" }],
        oldPassword: [{ validator: checkAge, trigger: "blur" }],
      },
      topiclist: {},
    };
  },
  components: {
    Header,
    Footer,
    MyCourseItem,
    Empty,
    MyArticleItem,
    Empty
  },
  watch:{
    $route(){
      let v = this.$route.query.active 
     if(v){
      try{
        
        this.active = parseInt(v)

      }catch(e){
        this.active=0;
      }
      this.changeTab(this.active)
     }
     
    }
  },
  created() {
    this.user = this.$store.state.user.userInfo;

     let v = this.$route.query.active 
     if(v){
      try{
        
        this.active = parseInt(v)

      }catch(e){
        this.active=0;
      }

     }

    if (this.user.gender === 1) {
      this.gender = "男";
    }
    if (this.user.gender === 2) {
      this.gender = "女";
    }
    if (this.user.gender === 3) {
      this.gender = "保密";
    }

    this.changeTab(this.active);

    setTimeout(() => {
      GetRecordList(4, this.i).then((res) => {
        this.total = res.total;
        this.list = res.data;
      });
      Getstatics().then((res) => {
        this.studytime = res.data;
      });
      GetDict("post_type").then((res) => {
        res.data.forEach((i) => {
          if (i.value == this.user.postCode) {
            this.name = i.label;
          }
        });
      });
    }, 100);
  },
  methods: {
    changeTab(index) {
      this.active = index;
      this.msg = this.menus[index];
      if (index === 1) {
        this.myCoursePage = 1;
        this.getMyCourseListData();
      }else if(index===2){
        this.myArticleCollectPage=1;
        this.getMyCollectArticleListData();
      }
    },

    //获取我的课程列表
    getMyCourseListData() {
      GetMyCourseList(this.myCoursePage, this.myCoursePageSize).then((res) => {
        this.myCourseTotal = res.total;
        this.myCourseList = res.data;
        this.myCourseEmpty=this.myCourseList .length===0;
      });
    },

    //我的课程点击分页
    myCoursePageChange(val){

        this.myCoursePage=val;
        this.getMyCourseListData();

    },

    //删除我的课程
    handleDelMyCourse(id) {
      this.$confirm("此操作将永久删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        JoinOrDelCourse({ courseId: id }).then(() => {
          this.$message.success("删除成功");
          this.myCoursePage = 1;
          this.getMyCourseListData();
        });
      });
    },

    //获取我收藏的文章
    getMyCollectArticleListData(){
        GetMyCollectArticleList(this.myArticleCollectPage,this.myArticleCollectPageSize).then(res=>{

            this.myCollectArticleList=res.data;
            this.myArticleCollectTotal = res.total;
            this.myArticleCollectEmpty = this.myCollectArticleList.length===0;
        })
    },
    //删除我收藏的文章
    delMyArticle(id){
      this.$confirm("此操作将永久删除该文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        AericleCollect({ articleId: id }).then(() => {
          this.$message.success("删除成功");
          this.myArticleCollectPage = 1;
          this.getMyCollectArticleListData();
        });
      });
    },
    //我收藏的文章点击分页
    myCollectArticlePageChange(val){
         this.myArticleCollectPage=val;
         this.getMyCollectArticleListData();
    },
    handelgo(id, rounds) {
      this.$router.push({
        path: "/result",
        name: "Result",
        query: {
          id,
          rounds,
        },
      });
    },
    submitForm(item) {
      PostUserPass(item).then((res) => {
        if (res) {
          this.$alert("密码修改成功,请用新密码重新登录");
          this.logout();
        }
      });
    },
    handelchange(val) {
      this.i = val;
      GetRecordList(4, this.i).then((res) => {
        this.total = res.total;
        this.list = res.data;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    haddelJump(id, rounds) {
      this.$router.push({
        path: "/result",
        name: "Result",
        query: { id, rounds },
      });
    },
    //退出登录
    logout() {
      this.$store.dispatch("user/logout");
    },
    handelto(i) {
      this.$router.push("/" + i);
    },
    handelsee(id, score, rounds) {
      this.$router.push({
        path: "/examanalysis",
        query: { id, score, rounds },
      });
    },
  },
};
</script>

<style scoped>
@import url(../assets/css/UserInfo.css);
</style>
