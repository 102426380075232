<template>
    <div class="my-course-item">
        <div class="cover-box">
            <img class="cover" :src="item.imageUrl"/>
            <div class="view-count">
                <img src="../assets/img/home/video-play.png"/>
                <span>{{item.count}}</span>
            </div>
        </div>
        <div style="margin-left: 10px;width: 100%;">
         <div style="display: flex;">
        <div class="title">{{item.title||'标题'}}</div>
        <div class="more">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                <i  class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="del"> <i  style="color: red;" class="el-icon-delete"></i><span style="color: #000;">删除</span></el-dropdown-item>
                    </el-dropdown-menu>
            </el-dropdown>
    
        </div>
         </div>
        <div style="display:flex;color: #3C3C43; font-size: 13px;margin-top: 15px;">
         <div :style="finished()?'color:#00C94C':'color:#FF3B30'">已学习{{ item.learned}}</div>   
         <div style="margin-left: 16px;">用时{{ item.time }}</div>   
        <div  style="margin-left: 16px;">共{{item.videoCount}}节课</div>
        </div> 
        <div style="display: flex;align-items: flex-end;">
        <div class="video-count" style="flex: 1;">笔记：{{ item.noteCount }}</div>
        <div class="continue">
            <el-button size="small" type="primary" @click="toDetail">继续学习</el-button>
        </div>
       </div>
       <div style="height:1px;background-color: #E8E8E8;margin-top: 20px;margin-bottom: 20px;"></div>
       </div>
    </div>
</template>

<script>
    export default {
        name: "CourseItem",
        props:{
            item:{
                type:Object,
                default:function () {

                    return {}
                }
            },
            direction:{
                type:String,
                default:'vertical'
            }
        },

        methods:{


            //检查是否完成学习
            finished(){
                
             let progress = this.item.learned.replace('%','')
 
             if(parseInt(progress)>=100){

                return true;
             }

             return false;

            },

            //下拉框点击事件
            handleCommand(command){
 
               if(command==='del'){
                this.$emit('onDel',this.item.id)
               }
         
            },
            //跳转到详情
            toDetail(){

                this.$router.push({path:`/courseDetail/${this.item.id}`})

            }
        }
    }
</script>

<style lang="less" scoped>
.my-course-item{
        width: 100%;
        background-color: #fff;
        display: flex;
        cursor: pointer;

        .cover-box{
            position: relative;
            height: 112px;
        }
        .cover{
            width: 200px;
            height: 112px;
            border-radius: 6px;
            background-color: #F3F4F6;
        }
        .view-count{
            position: absolute;
            bottom: 10px;
            left: 10px;
            display: flex;
            padding: 2px 5px;
            align-items: center;
            background-color: rgba(0,0,0,0.3);
            border-radius: 2px;
            img{
                width: 16px;
                height: 13px;
            }
            span{
                color: #fff;
                font-size: 12px;
                margin-left: 5px;

            }

        }
        .title{
            font-size: 17px;
            height: 48px;
            font-weight: bold;
            flex: 1;
            display: -webkit-box;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    
        .video-count{
            margin-top: 9px;
            font-size: 13px;
            color: rgba(60,60,67,0.6);
        }
 
        /deep/ .continue .el-button--primary {

            background-color: #3B89FF;
            border-color: #3B89FF;

        }
       
    }
   
</style>
